import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//Components
import InputHeader from '../Partials/InputHeader';
//Hooks
import useErrors from '../Hooks/useErrors';
//Functions
import { formatFieldId } from '../Utilities/Helper';
//PropTypes
const proptypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    rootClass: PropTypes.string,
};

/**
 * Gravity Forms SelectField Component
 * @todo
 * - Make into a custom dropdown if supported by browser
 * - handle on load event to update the field
 * @param {PropTypes}
 * @returns
 */
const SelectField = ({
    field,
    onChange,
    errors,
    rootClass = 'select-field',
}) => {
    const name = 'input_' + formatFieldId(field.id);
    const fieldErrors = useErrors(errors, name);

    useEffect(() => {}, []);

    const setSelectOptions = () => {
        return Object.keys(field.choices).map(key => {
            const input = field.choices[key];
            if (!input) return;

            const additionalParams = {};
            if (input.isSelected) additionalParams['selected'];

            return (
                <option
                    key={`option_${name}_${key}`}
                    value={input.value}
                    {...additionalParams}>
                    {input.text}
                </option>
            );
        });
    };

    return (
        <div className={`field ${rootClass} ${field.cssClass}`}>
            <InputHeader
                field={field}
                fieldName={name}
                errors={fieldErrors}
                rootClass={rootClass}
            />
            <select
                name={name}
                className={`${rootClass}__select${
                    fieldErrors.length > 0 ? ' input-error' : ''
                }`}
                onChange={e => {
                    onChange(name, e.target.value);
                }}
                placeholder={field.placeholder}>
                {field.choices &&
                    field.choices.length > 0 &&
                    setSelectOptions()}
            </select>
        </div>
    );
};

SelectField.propTypes = proptypes;
export default SelectField;
